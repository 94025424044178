<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline">
                  <template v-if="loading">
                    <Loading />
                  </template>
                  <template v-else>
                    <template v-if="Object.keys(detailMessage).length === empty">
                      <div class="column is-12">
                        <base-not-data
                          title="No hay datos del mensaje de contacto"
                        >
                          <router-link class="button button_micro_dark" to="/contacto">
                            REGRESAR
                          </router-link>
                        </base-not-data>
                      </div>
                    </template>
                    <template v-else>
                      <div class="column is-3">
                        <label class="label text_green_dark">Nombre</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.first_name }} {{ detailMessage.last_name }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Correo electrónico</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.email }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Teléfono</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.phone }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Fecha en que se realizó</label>
                        <p class="text_16 text_green_dark">{{ formatDate(detailMessage.created_at, 'DD/MM/YYYY') }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Estado</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.state }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">País</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.country }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Empresa</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.company }}</p>
                      </div>
                      <div class="column is-3">
                        <label class="label text_green_dark">Sitio Web</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.website || 'Sin información' }}</p>
                      </div>
                      <div class="column is-12">
                        <label class="label text_green_dark">Mensaje</label>
                        <p class="text_16 text_green_dark">{{ detailMessage.message }}</p>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '@/mixins/formats'

export default {
  name: 'ContactMessageMain',
  mixins: [mixins],
  components: {
    BaseNotData: () => import('@/components/UI/BaseNotData.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      loading: false,
      detailMessage: {},
      messageId: null,
      empty: 0
    }
  },
  methods: {
    ...mapActions(['getContactDetail']),
    async getContactDetailAction () {
      const { contactInfo } = await this.getContactDetail(this.messageId)
      this.loading = false
      this.detailMessage = contactInfo
    }
  },
  async beforeMount () {
    this.loading = true
    this.messageId = await this.$route.params.messageId
    this.getContactDetailAction()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .card-content {
    padding: 5% 10%;
  }
</style>
